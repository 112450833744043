import React from 'react';
import './termos-de-uso.css';
import Seo from '../components/Seo';
import { Link } from 'gatsby';
import Breadcrumbs from '../components/Breadcrumbs/breadcrumbs';
import Layout from '../components/layout';

const TermsOfUse = () => {
  const crumbs = [
    { path: '/', label: 'Página Inicial' },
    { path: '/termos-de-uso', label: 'Termos de Uso' }
  ];

  return (
    <Layout>
      <div className="terms-container">
        <Seo
          title="Termos de Uso | Prontuário Fácil"
          description="Leia os Termos de Uso do Prontuário Fácil para conhecer as regras e responsabilidades ao usar nosso sistema." // Descrição da página
        />
        <Breadcrumbs crumbs={crumbs} />
        <main className="terms-content">
          <h1>Termos de Uso</h1>
          <section>
            <h2>1. Introdução</h2>
            <p>Bem-vindo ao Prontuário Fácil. Ao acessar nosso sistema, você concorda em cumprir os seguintes termos e condições de uso, que juntamente com nossa política de privacidade, regem a relação entre Prontuário Fácil e você em relação a este sistema. Se você não concordar com alguma parte destes termos, por favor, não utilize nosso sistema.</p>
          </section>
          <section>
            <h2>2. Licença de Uso</h2>
            <p>Você tem permissão para acessar temporariamente o sistema, sob uma licença limitada, apenas para uso pessoal ou interno de sua empresa.</p>
            <p>Esta licença não inclui qualquer forma de venda ou modificação do sistema ou de seu conteúdo.</p>
            <p>O uso deste sistema é regido pelos seguintes termos de uso:</p>
            <ul>
              <li>Não copiar ou reproduzir conteúdo exceto para uso pessoal ou interno.</li>
              <li>Não utilizar o sistema ou conteúdos para qualquer propósito comercial sem consentimento expresso por escrito de Prontuário Fácil.</li>
            </ul>
          </section>
          <section>
            <h2>3. Acesso e Disponibilidade do Sistema</h2>
            <p>Prontuário Fácil se compromete a manter a disponibilidade do sistema para você, exceto em períodos de manutenção programada, eventos de força maior, e circunstâncias fora de nosso controle.</p>
          </section>
          <section>
            <h2>4. Obrigações e Responsabilidades do Usuário</h2>
            <p>O usuário compromete-se a não utilizar o sistema para atividades ilegais conforme a legislação brasileira e a não carregar, postar, transmitir ou de qualquer outra forma disponibilizar qualquer conteúdo que seja ilícito ou prejudicial.</p>
          </section>
          <section>
            <h2>5. Limitações de Responsabilidade</h2>
            <p>Prontuário Fácil não será responsável por interrupções ou indisponibilidade do sistema causadas por falhas técnicas ou problemas na Internet, nem por danos causados por terceiros, como ataques de hackers, falhas de fornecedores, ou falhas de serviços de hospedagem.</p>
          </section>
          <section>
            <h2>6. Modificações nos Termos</h2>
            <p>Prontuário Fácil reserva-se o direito de alterar estes termos de uso a qualquer momento. Tais alterações serão efetivas imediatamente após a postagem no sistema.</p>
          </section>
          <section>
            <h2>7. Lei Aplicável e Jurisdição</h2>
            <p>Estes termos de uso são regidos e interpretados de acordo com as leis do Brasil e qualquer disputa relacionada a estes termos será submetida à jurisdição exclusiva dos tribunais do Brasil.</p>
          </section>
          <section>
            <h2>8. Contato</h2>
            <p>Para quaisquer dúvidas ou esclarecimentos sobre os termos de uso, por favor, entre em contato conosco através de <Link to="/contato">Fale Conosco</Link>.</p>
          </section>
        </main>
      </div>
    </Layout>
  );
};

export default TermsOfUse;